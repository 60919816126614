import React from 'react';
import { Route } from 'react-router-dom';
import { Routes } from 'react-router-dom/dist';
import PageErrror from '../Error/PageErrror';
import BasicComponent from '../../pages/basic.component';

/**
 * The Anonymous Routes.
 *
 * @version 1.0.0
 * @author [Gina Chatzimarkaki]
 */
function Anonymous() {
  return (
    <Routes>
      <Route key="error" caseSensitive path={"/error"} element={<PageErrror />} />
      <Route key="redirect" caseSensitive path="/:path" element={<BasicComponent/>} />
      <Route key="empty" caseSensitive path="*" element={<PageErrror/>} />
    </Routes>
  )
}

export default Anonymous;