import { request } from '../constants/alias';
import { API } from "../constants/config";

/**
 * The rest request which attempts to fetch qrcode path redirect url.
 * 
 * @param path the path url to fetch the redirect url 
 * 
 * @returns an object with an attribute containing the vat details
 */
export async function getRedirectURL(path) {
    return await request.get(`${API}qrcode/redirect/${path}`);
}

const qrcodePathApi = {
    getRedirectURL
}

export default qrcodePathApi;