import React from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import Anonymous from '../Routes/Anonymous';


/**
 * The Auth controller.
 *
 * @version 1.0.0
 * @author [Gina Chatzimarkaki]
 */
function AnonymousController() {
    return (
        <React.Fragment>
            <Anonymous key={"anon-routes"} />
        </React.Fragment>
    )
}

function App() {
    return (
        <Router>
            <AnonymousController />
        </Router>
    )
}

export default App;