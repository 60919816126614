const EN = {
  errorPages: {
    somethingWentWrong: 'Oops! Looks like you\'re lost in space. Please try again.',
  },
  languages: {
    en: "English",
    de: "German",
    fr: "French",
    it: "Italian",
    ru: "Russian",
    el: "Greek",
    uk: "Ukrainian",
    es: "Spanish",
    nl: "Dutch",
    cs: "Czech",
    pl: "Polish",
    bg: "Bulgarian",
    ar: "Arabic",
    et: "Estonian",
    lt: "Lithuanian",
    ro: "Romanian",
    tr: "Turkish"
  }
}

export default EN;
